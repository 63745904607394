<button mat-raised-button class="filter-btn" (click)="reset()" matTooltip="Reset Filters" matTooltipPosition="above"
    aria-label="Button that Resets All the Filters." matTooltipClass="resetFiltersClass">
    <mat-icon>restart_alt</mat-icon>
</button>

<app-filter-set-select-btn class="filter-btn" [(groupIds)]="groupIds" [(userIds)]="userIds" [(teamIds)]="teamIds"
    [(phaseIds)]="phaseIds" [(tags)]="tags" [(zoneIds)]="zoneIds" [(completeStatus)]="completeStatus"
    [(onlyMyTasks)]="onlyMyTasks" (onClosed)="filter(false)">
</app-filter-set-select-btn>

<app-harvest-select-multi-btn 
    class="filter-btn" 
    [showUpcomingHarvests]="forAnalytics"
    [appStorageNeeded]="!forAnalytics" 
    [(selected)]="harvestIds" 
    [(selectedHarvests)]="harvests" 
    (onClosed)="filter()" 
    (loaded)="loaded()">
</app-harvest-select-multi-btn>

<app-task-group-select-multi-btn 
    class="filter-btn" 
    [showGroupsForAnalytics]="forAnalytics" 
    [(selected)]="groupIds"
    [(selectedGroups)]="groups" 
    (onClosed)="filter()" 
    (loaded)="loaded()">
</app-task-group-select-multi-btn>

<app-zone-select-multi-btn *ngIf="!forAnalytics" class="filter-btn" [(selected)]="zoneIds" (onClosed)="filter()">
</app-zone-select-multi-btn>

<app-phase-select-multi-btn 
    class="filter-btn" 
    [(selected)]="phaseIds" 
    (onClosed)="filter()">
</app-phase-select-multi-btn>

<app-team-select-multi-btn 
    class="filter-btn" 
    [(selected)]="teamIds" 
    [(selectedTeams)]="teams" 
    (onClosed)="filter()">
</app-team-select-multi-btn>

<app-user-select-multi-btn 
    class="filter-btn" 
    [(selected)]="userIds" 
    [(selectedUsers)]="users" 
    (onClosed)="filter()">
</app-user-select-multi-btn>

<app-tag-select-multi-btn *ngIf="!forAnalytics" class="filter-btn" [events]="events" [(selected)]="tags"
    (onClosed)="filter()">
</app-tag-select-multi-btn>

<app-other-filter-options-btn *ngIf="!forAnalytics" class="filter-btn" [(completeStatus)]="completeStatus"
    [(onlyMyTasks)]="onlyMyTasks" (onClosed)="filter()">
</app-other-filter-options-btn>

<div class="help-icon-container" *ngIf="displayHelp">
    <mat-icon [ngxTippy]="filterHelp" tippyName="filterHelp" class="tooltip-help-filter">
        help_outline
    </mat-icon>
    
    <ng-template #filterHelp let-name let-data="data">
        <div class="tippy-container-filter">
            <p class="header"><b>Filter and graphs: </b></p>
            <p>1. Based on your selection(s) in the filter, all graphs will update to show data based on your query. Your filter selection(s) will also be displayed in the container below the filter.</p>
            <p>2. Range and intervals are specific to each graph.</p>
            <p>3. You can also choose to expand each individual graph to look at a more detailed view.</p>
        </div>
    </ng-template>
</div>

<!-- <mat-form-field *ngIf="filterSets.length > 1" style="display: inline-block !important" appearance="outline" class="filterSetSelect">
    <mat-label>Saved Filter Sets</mat-label>
    <mat-select [(ngModel)]="selectedFilterSet" (selectionChange)="filterSetChange($event)">
        <mat-option [value]="filterSet" *ngFor="let filterSet of filterSets">{{filterSet.name}}</mat-option>
    </mat-select>
</mat-form-field> -->

<!-- <button mat-raised-button class="pad-btn" title="Add Filter Set" (click)="addFilterSet()">
    <mat-icon>bookmark_add</mat-icon>
</button>
<button mat-raised-button *ngIf="selectedFilterSet != null && selectedFilterSet.id != null" class="pad-btn" title="Remove Filter Set" (click)="removeFilterSet()">
    <mat-icon>bookmark_remove</mat-icon>
</button> -->

<div class="row" style="padding-top: 10px" [ngClass]="{ minimized: isMinimized }">
    <div class="col-12">
        <div class="expand-button">
            <button mat-icon-button (click)="toggleView()">
            <mat-icon>{{ isMinimized ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</mat-icon>
            </button>
            {{ isMinimized ? 'Saved Views' : '' }}
        </div>
        <div style="display: inline-block" [hidden]="isMinimized">
            <app-filter-set-chip-list (filterSetSelected)="filterSetChanged($event)"></app-filter-set-chip-list>
        </div>
    </div>
</div>

<!-- <div class="row" style="padding-top: 10px">
    <div class="col-12">
        <app-filter-set-chip-list (filterSetSelected)="filterSetChanged($event)"></app-filter-set-chip-list>
    </div>
</div> -->

<div class="row" style="padding-top: 10px;" *ngIf="displaySummary">
    <div class="col-12">
        <mat-card>
            <mat-card-content>
                <p class="filter-summary-item" (click)="harvestShowmore=!harvestShowmore">
                    <span #harvestContent [class.truncate]="!harvestShowmore"><b style="font-weight: bolder;">Harvests:</b> {{harvestSummary}} </span>
                    <span *ngIf="!harvestShowmore && isEllipsisActive(harvestContent)" class="show-more"><mat-icon>expand_more</mat-icon></span>
                    <span *ngIf="harvestShowmore" class="show-more"><mat-icon>expand_less</mat-icon></span>
                </p>
                <p class="filter-summary-item" (click)="groupShowmore=!groupShowmore">
                    <span #groupContent [class.truncate]="!groupShowmore"><b style="font-weight: bolder;">Task Groups:</b> {{groupSummary}} </span>
                    <span *ngIf="!groupShowmore && isEllipsisActive(groupContent)" class="show-more"><mat-icon>expand_more</mat-icon></span>
                    <span *ngIf="groupShowmore" class="show-more"><mat-icon>expand_less</mat-icon></span>
                </p>
                <p class="filter-summary-item" (click)="phaseShowmore=!phaseShowmore">
                    <span #phaseContent [class.truncate]="!phaseShowmore"><b style="font-weight: bolder;">Phases:</b> {{phaseSummary}} </span>
                    <span *ngIf="!phaseShowmore && isEllipsisActive(phaseContent)" class="show-more"><mat-icon>expand_more</mat-icon></span>
                    <span *ngIf="phaseShowmore" class="show-more"><mat-icon>expand_less</mat-icon></span>
                </p>
                <p class="filter-summary-item" (click)="teamShowmore=!teamShowmore">
                    <span #teamContent [class.truncate]="!teamShowmore"><b style="font-weight: bolder;">Teams:</b> {{teamSummary}} </span>
                    <span *ngIf="!teamShowmore && isEllipsisActive(teamContent)" class="show-more"><mat-icon>expand_more</mat-icon></span>
                    <span *ngIf="teamShowmore" class="show-more"><mat-icon>expand_less</mat-icon></span>
                </p>
                <p class="filter-summary-item" (click)="userShowmore=!userShowmore">
                    <span #userContent [class.truncate]="!userShowmore"><b style="font-weight: bolder;">Users:</b> {{userSummary}} </span>
                    <span *ngIf="!userShowmore && isEllipsisActive(userContent)" class="show-more"><mat-icon>expand_more</mat-icon></span>
                    <span *ngIf="userShowmore" class="show-more"><mat-icon>expand_less</mat-icon></span>
                </p>
            </mat-card-content>
        </mat-card>
    </div>
</div>