import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { FilterSetSelectBtnComponent } from 'app/buttons/filter-set-select-btn/filter-set-select-btn.component';
import { HarvestSelectMultiBtnComponent } from 'app/buttons/harvest-select-multi-btn/harvest-select-multi-btn.component';
import { PhaseSelectMultiBtnComponent } from 'app/buttons/phase-select-multi-btn/phase-select-multi-btn.component';
import { TagSelectMultiBtnComponent } from 'app/buttons/tag-select-multi-btn/tag-select-multi-btn.component';
import { TaskGroupSelectMultiBtnComponent } from 'app/buttons/task-group-select-multi-btn/task-group-select-multi-btn.component';
import { TeamSelectMultiBtnComponent } from 'app/buttons/team-select-multi-btn/team-select-multi-btn.component';
import { UserSelectMultiBtnComponent } from 'app/buttons/user-select-multi-btn/user-select-multi-btn.component';
import { ZoneSelectMultiBtnComponent } from 'app/buttons/zone-select-multi-btn/zone-select-multi-btn.component';
import { DateFiltersBtnComponent } from 'app/buttons/date-filters-btn/date-filters-btn.component';
import { IntervalSelectBtnComponent } from 'app/buttons/interval-select-btn/interval-select-btn.component';
import { FilterSetSaveDialogComponent } from 'app/dialogs';
import * as models from 'app/models';
import * as services from 'app/services';
import * as viewmodels from 'app/viewmodels';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filter-section',
  templateUrl: './filter-section.component.html',
  styleUrls: ['./filter-section.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FilterSectionComponent implements OnInit, AfterViewInit, OnDestroy {

  companyIdSub: Subscription

  @ViewChild(FilterSetSelectBtnComponent) filterSetSelectBtnComponent: FilterSetSelectBtnComponent;
  @ViewChild(HarvestSelectMultiBtnComponent) harvestSelectMultiBtnComponent: HarvestSelectMultiBtnComponent;
  @ViewChild(TaskGroupSelectMultiBtnComponent) taskGroupSelectMultiBtnComponent: TaskGroupSelectMultiBtnComponent;
  @ViewChild(ZoneSelectMultiBtnComponent) zoneSelectMultiBtnComponent: ZoneSelectMultiBtnComponent;
  @ViewChild(PhaseSelectMultiBtnComponent) phaseSelectMultiBtnComponent: PhaseSelectMultiBtnComponent;
  @ViewChild(TeamSelectMultiBtnComponent) teamSelectMultiBtnComponent: TeamSelectMultiBtnComponent;
  @ViewChild(UserSelectMultiBtnComponent) userSelectMultiBtnComponent: UserSelectMultiBtnComponent;
  //@ViewChild(TagSelectMultiBtnComponent) tagSelectMultiBtnComponent: TagSelectMultiBtnComponent;

  @Input() events: models.Event[];
  @Input() forAnalytics: boolean = false;
  @Input() displaySummary: boolean = false;
  @Input() displayHelp: boolean = false;
  
  @Output() onFilter = new EventEmitter();
  @Output() initialLoad = new EventEmitter();

  @Output() resetChartView = new EventEmitter();

  harvestIds: string[] = [];
  groupIds: string[] = [];
  zoneIds: string[] = [];
  phaseIds: string[] = [];
  teamIds: string[] = [];
  userIds: string[] = [];
  tags: string[] = [];
  completeStatus: string;
  onlyMyTasks: boolean = false;
  dateRange: models.DateRanger;
  interval: string;

  harvests: viewmodels.MultiSelect[] = [];
  groups: viewmodels.MultiSelect[] = [];
  teams: viewmodels.MultiSelect[] = [];
  users: viewmodels.MultiSelect[] = [];

  calls: number = 2

  get filterResponse() {
    let result: viewmodels.FilterResponse;
    if (!this.forAnalytics) {
      result = {
        harvestIds: this.harvestIds,
        groupIds: this.groupIds,
        zoneIds: this.zoneIds,
        phases: this.phaseIds,
        teamIds: this.teamIds,
        userIds: this.userIds,
        tags: this.tags,
        completeStatus: this.completeStatus,
        onlyMyTasks: this.onlyMyTasks
      }
    } else {
      result = {
        harvestIds: this.harvestIds,
        groupIds: this.groupIds,
        phases: this.phaseIds,
        teamIds: this.teamIds,
        userIds: this.userIds,
      }
    }
    return result;
  }

  harvestShowmore: boolean = false;
  get harvestSummary() {
    if(this.harvests.length == 0) {
      return 'None';
    }
    return this.harvests.map(i => i.displayValue).join(', ');
  }

  groupShowmore: boolean = false;
  get groupSummary() {
    if(this.groups.length == 0) {
      return 'None';
    }
    return this.groups.map(i => i.displayValue).join(', ');
  }

  phaseShowmore: boolean = false;
  get phaseSummary() {
    if(this.phaseIds.length == 0) {
      return 'All';
    }
    return this.phaseIds.join(', ');
  }

  teamShowmore: boolean = false;
  get teamSummary() {
    if(this.teamIds.length == 0) {
      return 'All';
    }
    return this.teams.map(i => i.displayValue).join(', ');
  }

  userShowmore: boolean = false;
  get userSummary() {
    if(this.users.length == 0) {
      return 'All';
    }
    return this.users.map(i => i.displayValue).join(', ');
  }

  isEllipsisActive(e) {
    return (e.offsetWidth < e.scrollWidth);
  }

  companyId: string;
  constructor(
    private helperService: services.HelperService
    , private appStorageService: services.AppStorage
  ) { }

  async setUpData(filterData: viewmodels.Filter) {
    this.harvestIds = filterData != null ? filterData.harvestIds : this.harvestIds;
    this.groupIds = filterData != null ? filterData.groupIds : this.groupIds;
    this.phaseIds = filterData != null ? filterData.phases : [];
    this.zoneIds = filterData != null ? filterData.zoneIds : [];
    // this.phaseIds = [];
    this.teamIds = filterData != null ? filterData.teamIds : [];
    this.userIds = filterData != null ? filterData.userIds : [];
    this.tags = filterData != null ? filterData.tags : [];
    this.completeStatus = filterData != null ? filterData.completeStatus : 'both';
    this.onlyMyTasks = filterData != null ? filterData.onlyMyTasks : false;

    this.calls = 2
    this.filterSetSelectBtnComponent.clearSelected()
    this.filterSetSelectBtnComponent.filterSets = [];
    //this.harvestSelectMultiBtnComponent.selected = [];
    //this.harvestSelectMultiBtnComponent.getHarvests();
    //this.taskGroupSelectMultiBtnComponent.selected = [];
    //this.taskGroupSelectMultiBtnComponent.getGroups();
    !this.forAnalytics ? this.zoneSelectMultiBtnComponent.getZones() : null;
    this.phaseSelectMultiBtnComponent.getPhases();
    this.teamSelectMultiBtnComponent.getTeams();
    this.userSelectMultiBtnComponent.getUsers();
    this.filter(false);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.companyId = this.helperService.currentCompanyId;
    this.companyIdSub = this.helperService.getCurrentCompany().subscribe(data => {
      this.companyId = data.uid;
      this.setUpData(!this.forAnalytics ? this.appStorageService.companyFiltersObject : null);
    })
    this.setUpData(!this.forAnalytics ? this.appStorageService.companyFiltersObject : null);
  }

  saveFilterDataInLocalStorage() {
    let filterData: viewmodels.CompanyFilter = {
      companyId: this.companyId,
      companyFilters: {
        harvestIds: this.harvestIds,
        groupIds: this.groupIds,
        phases: this.phaseIds,
        zoneIds: this.zoneIds,
        teamIds: this.teamIds,
        userIds: this.userIds,
        tags: this.tags,
        completeStatus: this.completeStatus,
        onlyMyTasks: this.onlyMyTasks
      }
    }
    this.appStorageService.companyFiltersObject = filterData.companyFilters;
  }

  ngOnDestroy(): void {
    if(!this.forAnalytics) {
      this.saveFilterDataInLocalStorage();
    }
    this.companyIdSub.unsubscribe();
  }

  filter(clearFilterSet: boolean = true) {
    let filter = this.filterResponse;
    if(!this.forAnalytics) {
      this.saveFilterDataInLocalStorage();
    }
    this.onFilter.emit(filter);
    if (clearFilterSet) {
      this.filterSetSelectBtnComponent.clearSelected();
    }
  }

  filterSetChanged(filterSet: models.FilterSet) {
    //this.harvestIds = filterSet.harvestIds;
    this.groupIds = filterSet.groupIds;
    this.phaseIds = filterSet.phases;
    this.zoneIds = filterSet.zoneIds;
    this.teamIds = filterSet.teamIds;
    this.userIds = filterSet.userIds;
    this.tags = filterSet.tags;
    this.completeStatus = filterSet.completeStatus;
    this.onlyMyTasks = filterSet.onlyMyTasks;
    this.filter();
  }

  // filterSetSelectClosed(filter: boolean) {
  //   if(filter){
  //     this.filter();
  //   }
  // }

  async harvestsChanged(harvestIds: Array<string>) {
    await this.helperService.wait(2000);
    this.harvestIds = harvestIds;
    this.saveFilterDataInLocalStorage(); // to add forAnalytics here once PR #409 is approved, if forAnalytics we need to save in app storage otherwise not
    this.harvestSelectMultiBtnComponent.initialLoad = false;
    this.harvestSelectMultiBtnComponent.getHarvests();
  }

  groupsChanged(groupIds: Array<string>) {
    this.groupIds = groupIds;
    this.taskGroupSelectMultiBtnComponent.getGroups();
  }

  async loaded() {
    this.calls--;
    if (this.calls == 0) {
      let filter = this.filterResponse;
      this.initialLoad.emit(filter);
    }
  }

  async reset() {
    this.filterSetSelectBtnComponent.default();
    this.harvestSelectMultiBtnComponent.initialLoad = false;
    this.harvestSelectMultiBtnComponent.selected = [];
    this.taskGroupSelectMultiBtnComponent.selected = [];
    await this.harvestSelectMultiBtnComponent.getHarvests(true);
    await this.taskGroupSelectMultiBtnComponent.getGroups();
    this.resetChartView.emit({ expand: false });
    this.filter();
  }

  isMinimized = false;
  toggleView(): void {
    this.isMinimized = !this.isMinimized;
  }
}
