
<div class="row">
    <div class="col-sm-12">
        <div style="float: right;">
            <button mat-raised-button class="pad-btn" [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button *ngIf="canAdd" mat-menu-item (click)="add()" title="Add Templated Task">
                    <mat-icon aria-hidden="false" aria-label="Add Templated Task">add</mat-icon>
                    <span>Add Templated Task</span>
                </button>
                <button *ngIf="canAdd" mat-menu-item (click)="migrate()" title="Migrate Templated Task">
                    <mat-icon aria-hidden="false" aria-label="Migrate Templated Task">trending_flat</mat-icon>
                    <span>Migrate Templated Task</span>
                </button>
                <button mat-menu-item title="Export as CSV" (click)="exportAsCSV()">
                    <mat-icon aria-hidden="false" aria-label="Export as CSV">file_download</mat-icon>
                    <span>Export as CSV</span>
                </button>
            </mat-menu>
        </div>
    </div>
</div>

<div #taskLibraryList>
    <ag-grid-angular style="width: 100%; min-height:400px; height: calc(30vh);" class="ag-theme-balham"
        [gridOptions]="gridOptions"
        [suppressRowClickSelection]="selectionLocked"
        [(rowData)]="templatedTasks">
    </ag-grid-angular>
</div>
