
<div class="row" style="padding-bottom: 15px" *ngIf="companyId != null">
  <div class="col-sm-8">
    <button mat-raised-button class="filter-btn" (click)="reset()" matTooltip="Reset Filters" matTooltipPosition="above" aria-label="Button that Resets All the Filters." matTooltipClass="resetFiltersClass">
      <mat-icon>restart_alt</mat-icon>
    </button>
    
    <app-template-select-multi-btn
      class="filter-btn" 
      [(selected)]="templateIds"
      [(allSelected)]="allTemplatesSelected"
      (onClosed)="getHarvests()">
    </app-template-select-multi-btn>
    
    <app-harvest-select-multi-btn
      class="filter-btn" 
      [showUpcomingHarvests]="false"
      [appStorageNeeded]="false"
      [(selected)]="harvestIds"
      (onClosed)="getHarvests()"
      (loaded)="getHarvests()">
    </app-harvest-select-multi-btn>
    
    <app-zone-select-multi-btn
      class="filter-btn" 
      [(selected)]="zoneIds"
      (onClosed)="getHarvests()"
    >
    </app-zone-select-multi-btn>
    
    <mat-slide-toggle class="gridbtn" [(ngModel)]="appStorage.showFinishedHarvests" (change)="getHarvests()">Include Finished Harvests</mat-slide-toggle>
  </div>
  <div class="col-sm-4">
    <div style="float: right">
      <!-- <button mat-raised-button class="select-button pad-btn" matTooltip="Show/Hide Columns" [matMenuTriggerFor]="selectCols">
        <mat-icon>view_column</mat-icon>
        <mat-select #selectCols multiple panelClass="left-panel" [(ngModel)]="selectedColumnGroups" (closed)="setUpColumns()">
          <mat-option *ngFor="let columnGroup of columnGroups" [value]="columnGroup">{{columnGroup.name}}</mat-option>
        </mat-select>
      </button> -->

      <app-dutchie-btn (addedHarvests)="addedHarvests($event)"></app-dutchie-btn>

      <button mat-raised-button *ngIf="canSeeHarvestAdd || canSeeHarvestGroupDelete" class="gridbtn" [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button *ngIf="canSeeHarvestAdd" mat-menu-item (click)="addHarvest()" title="Add Harvest">
          <mat-icon aria-hidden="false" aria-label="Add Harvest">add</mat-icon>
          <span>Add Harvest</span>
        </button>

        <!-- Shift Harvest Button -->
        <app-harvest-shift-btn [dropDownMenu]="true"></app-harvest-shift-btn>

        <button *ngIf="canSeeHarvestGroupDelete" mat-menu-item (click)="harvestGroupDelete()" title="Delete Harvests">
          <mat-icon aria-hidden="false" aria-label="Delete Harvests">delete</mat-icon>
          <span>Delete Harvests</span>
        </button>

        <!-- <app-leaflogix-harvest-sync-btn (addedHarvests)="addedHarvests($event)"></app-leaflogix-harvest-sync-btn>
        <app-leaflogix-sync-btn></app-leaflogix-sync-btn> -->
      </mat-menu>
    </div>
  </div>
</div>
<div class="row" style="width: 100%; padding-bottom: 15px; margin: 0px">
  <div class="col-sm-12" style="padding: 0;">
    <mat-accordion>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>Timeline</mat-panel-title>
        </mat-expansion-panel-header>
        <app-timeline [dataList]="timelineData" [dateFormat]="timelineDateFormat" [colors]="timelineColors"></app-timeline>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<div class="row" data-step="1" data-intro="Double click on a row to see the details of a Harvest." data-position='right' #harvestList>
  <ag-grid-angular
    style="width: 100%; min-height:400px; height: calc(100vh - 300px);" 
    class="ag-theme-balham"
    [gridOptions]="gridOptions"
    (cellDoubleClicked)="selectedCell($event)">
  </ag-grid-angular>
</div>
<div class="row" style="padding: 15px 0px">
  <div class="col-sm-12 right">
    <button class="gridbtn" mat-raised-button (click)="exportAsCSV()">Export as CSV</button>
  </div>
</div>