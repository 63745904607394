import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import * as models from 'app/models';
import * as services from 'app/services';

@Component({
  selector: 'app-template-migrate-dialog',
  templateUrl: './template-migrate-dialog.component.html',
  styleUrls: ['./template-migrate-dialog.component.scss']
})
export class TemplateMigrateDialogComponent implements OnInit {

  saveClicked: boolean = false;

  template: models.Template;

  companyIdsToExclude: string[] = [];
  otherCompanies: models.Company[] = [];

  constructor(
    private dialogRef: MatDialogRef<TemplateMigrateDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private helperService: services.HelperService
    , private notifierService: services.NotifierService
    , private templateService: services.TemplateService
  ) { 
    if(data) {
      this.template = this.helperService.deepCopy(data);
    }
  }

  ngOnInit(): void {
    this.companyIdsToExclude = [this.helperService.currentCompanyId];
  }

  moveClick() {

    if(this.otherCompanies.length === 0) {
      this.notifierService.error('No companies selected to migrate Task Library items to.')
      return;
    }

    this.notifierService.confirm(
      'Migrate Template?', 
      `Are you sure you want to migrate this template to ${this.otherCompanies.length} different companies?`,
      () => {
        this.move();
      }, () => {})
  }

  async move() {
    this.saveClicked = true;
    for (let i = 0; i < this.otherCompanies.length; i++) {
      const company = this.otherCompanies[i];

      await this.templateService.save(this.template, company.uid);
    }

    this.saveClicked = false;
    this.notifierService.success('Template migrated successfully.')
    this.dialogRef.close(true);
  }

}
