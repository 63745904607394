import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import * as models from 'app/models';
import * as services from 'app/services';

@Component({
  selector: 'app-filter-set-chip-list',
  templateUrl: './filter-set-chip-list.component.html',
  styleUrls: ['./filter-set-chip-list.component.scss']
})
export class FilterSetChipListComponent implements OnInit {
  
  @Output() filterSetSelected: EventEmitter<models.FilterSet> = new EventEmitter();

  filterSets: models.FilterSet[] = [];
  selectedId: string;

  constructor(
    private filterSetService: services.FilterSetService
  ) { }

  ngOnInit(): void {
    this.getFilterSets();
  }

  async getFilterSets() {
    if(this.filterSets.length > 0){
      return;
    }
    this.filterSets = await this.filterSetService.getAll();
  }

  chipClick(filterSet: models.FilterSet) {
    //this.selectedId = filterSet.id;
    this.filterSetSelected.emit(filterSet);
  }

}
