import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import {
    AfterViewInit,
    Component,
} from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { NotifierService } from 'app/services';

@Component({
    selector: 'editor-cell',
    template: `
    <div class="cont">
        <div style="padding-bottom: 5px">
            <div style="color: lightgrey; text-align: right">
                <mat-icon style="vertical-align: middle;">keyboard_return</mat-icon> <span>to close</span>
            </div>
        </div>
        <mat-radio-group aria-labelledby="example-radio-group-label" [(ngModel)]="type">
            <mat-radio-button value="single">Single</mat-radio-button>
            <mat-radio-button value="list">List</mat-radio-button>
            <mat-radio-button value="range">Range</mat-radio-button>
            <mat-radio-button value="sliding-range">Sliding Range</mat-radio-button>
        </mat-radio-group>
        <ng-container *ngIf="type == 'single'">
            <mat-form-field>
                <input matInput type="number" min="0" [(ngModel)]="single" placeholder="Day" (ngModelChange)="single = validateInput($event)" (input)="single = validateInput(single)">
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="type == 'list'">
            <mat-chip-list #chipList aria-label="Days">
                <mat-chip *ngFor="let item of list" [selectable]="true" [removable]="true"
                    (removed)="remove(item)">
                    {{item}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input type="number" placeholder="New day..." [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
        </ng-container>
        <ng-container *ngIf="type == 'range'">
            <div class="row">
                <div class="col-6">
                    <mat-form-field>
                        <input matInput type="number" [(ngModel)]="rangeStart" placeholder="Start" (ngModelChange)="rangeStart = validateInput($event)" (input)="rangeStart = validateInput(rangeStart)">
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field>
                        <input matInput type="number" [(ngModel)]="rangeEnd" placeholder="End" (ngModelChange)="rangeEnd = validateInput($event)" (input)="rangeEnd = validateInput(rangeEnd)">
                    </mat-form-field>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="type == 'sliding-range'">
            <div class="row">
                <div class="col-6">
                    <mat-form-field>
                        <input matInput type="number" [(ngModel)]="rangeStart" placeholder="Start" (ngModelChange)="rangeStart = validateInput($event)" (input)="rangeStart = validateInput(rangeStart)">
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field>
                        <input matInput type="number" [(ngModel)]="rangeEnd" placeholder="End" (ngModelChange)="rangeEnd = validateInput($event)" (input)="rangeEnd = validateInput(rangeEnd)">
                    </mat-form-field>
                </div>
            </div>
        </ng-container>
    </div>
    `,
    styles: [
        `
      .cont{
        padding:20px;
      }
      .mat-radio-button ~ .mat-radio-button {
        margin-left: 16px;
      }
      `
    ],
})
export class DayEditorCell implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

    type: string = 'single';

    single: number = 0;

    list: Array<number> = [0];

    rangeStart: number = 0;
    rangeEnd: number = 1;

    constructor(private notifierService: NotifierService){

    }

    isPopup(): boolean {
        return true;
    }

    ngAfterViewInit() {
    }

    agInit(params: any): void {
        this.params = params;
        let value: string = this.params.value;

        if (value.includes(',')) {
            this.type = 'list';
            this.list = value.split(',').map(i => +i);

            //others
            this.rangeStart = this.list[0]
            this.rangeEnd = this.list[1]
            this.single = this.list[0]

        } else if (value.includes('-')) {
            this.type = 'range';
            let split = value.split('-');
            this.rangeStart = +split[0]
            this.rangeEnd = +split[1]

            //others
            this.single = this.rangeStart;
            this.list = [this.rangeStart, this.rangeEnd];
        } else if (value.includes('/')) {
            this.type = 'sliding-range';
            let split = value.split('/');
            this.rangeStart = +split[0]
            this.rangeEnd = +split[1]

            //others
            this.single = this.rangeStart;
            this.list = [this.rangeStart, this.rangeEnd];
        } else {
            this.type = 'single';
            this.single = +value;

            //others
            this.list = [this.single];
            this.rangeStart = this.single;
        }
    }

    getValue(): any {
        switch (this.type) {
            case 'single': {
                return this.single.toString();
            }
            case 'list': {
                if (this.list.length == 0) {
                    return ['0'];
                }
                return this.list.map(i => +i).sort((a, b) => {return a-b}).join(',');
            }
            case 'range': {
                return this.rangeStart + '-' + this.rangeEnd;
            }
            case 'sliding-range': {
                return this.rangeStart + '/' + this.rangeEnd;
            }
            default: {
                return '0';
            }
        }
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        if (this.list == null) {
            this.list = [];
        }

        // Validate value
        if (!isNaN(+value) && +value >= 0) {
            this.list.push(+value);
        } else {
            this.notifierService.error('Day cannot be negative');
            return;
        }

        // Add our fruit
        if ((value || 0)) {
            this.list.push(+value);
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(fruit: string): void {
        const index = this.list.indexOf(+fruit);

        if (index >= 0) {
            this.list.splice(index, 1);
        }
    }

    validateInput(value) {
        if (value < 0) {
            this.notifierService.error('Day cannot be negative');
            value = 0; // Reset to 0 if the value is negative
        }

        return value;
    }
}
