import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import * as models from 'app/models';
import * as services from 'app/services';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-company-select-multiple',
  templateUrl: './company-select-multiple.component.html',
  styleUrls: ['./company-select-multiple.component.scss']
})
export class CompanySelectMultipleComponent implements OnInit, OnDestroy {

  @Input() companyIdsToExclude: string[] = [];

  selectedCompaniesValue: Array<models.Company>;
  @Input()
  get selectedCompanies() {
    return this.selectedCompaniesValue;
  }
  @Output() selectedCompaniesChange = new EventEmitter();
  set selectedCompanies(val) {
    if(val == null){
      val = [];
    }
    this.selectedCompaniesValue = val;
    this.selectedCompaniesChange.emit(this.selectedCompaniesValue);
    this.setForm();
  }

  companies: models.Company[] = [];
  selectedCompaniesFC = new FormControl('');

  loading: boolean = false;

  public companyCtrl: FormControl = new FormControl();
  public companyFilterCtrl: FormControl = new FormControl();
  public filteredCompanies: ReplaySubject<models.Company[]> = new ReplaySubject<models.Company[]>(1);
  private filteredCompaniesCache: models.Company[] = [];
  protected _onDestroy = new Subject<void>();

  protected filterCompanies() {
    if (!this.companies) {
      return;
    }
    // get the search keyword
    let search = this.companyFilterCtrl.value;
    if (!search) {
      this.filteredCompanies.next(this.companies.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the companies
    this.filteredCompaniesCache = this.companies.filter(c => {
      return (c.name != null && c.name.toLowerCase().indexOf(search) > -1)
    })
    this.filteredCompanies.next(this.filteredCompaniesCache);
  }

  constructor(
    private companyService: services.CompanyService
  ) { }

  ngOnInit(): void {
    this.getCompanies();

    // listen for search field value changes
    this.companyFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCompanies();
      });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  companyChange(newValue: models.Company[]) {
    this.selectedCompaniesFC.setValue(newValue);
  }

  selectAll() {
    this.selectedCompaniesFC.setValue(this.filteredCompaniesCache);
  }

  async getCompanies() {
    this.loading = true;
    let companyResponse = await this.companyService.getAllCompaniesForUser();

    if(companyResponse.success) {
      this.companies = companyResponse.value.filter(i => !this.companyIdsToExclude.includes(i.uid));
      this.filterCompanies();
    }
    this.loading = false;
  }

  setForm() {
    this.selectedCompaniesFC.setValue(this.selectedCompanies);
  }

  setSelected() {
    this.selectedCompanies = this.selectedCompaniesFC.value;
  }

}
