<div class="row">
    <div class="col-12">
        <h3 style="float: left;">Data Library</h3>
        <div style="float: right;">
            <button class="gridbtn" mat-raised-button (click)="migrate()" title="Migrate Data Collection Definition">
                <mat-icon>trending_flat</mat-icon>
            </button>
            <button class="gridbtn" mat-raised-button (click)="add()" title="Add Data Collection Definition">
                <mat-icon>add</mat-icon>
            </button>
            <button class="gridbtn" mat-raised-button [disabled]="!dirty" (click)="save()" title="Save Data Library">
                <mat-spinner *ngIf="saving" diameter=25 strokeWidth=4></mat-spinner>
                <mat-icon *ngIf="!saving" aria-hidden="false" aria-label="Save Zone">save</mat-icon>
            </button>
            <button class="gridbtn" mat-raised-button [disabled]="!dirty" (click)="getDataLibrary()" title="Undo">
                <mat-icon aria-hidden="false" aria-label="Undo">undo</mat-icon>
            </button>
            <!-- <button mat-raised-button class="pad-btn" [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item id="sensor_add_btn" title="Add Sensor" (click)="add()">
                  <mat-icon aria-hidden="false" aria-label="Add Sensor">add</mat-icon>
                  <span>Add Sensor</span>
              </button>
            </mat-menu> -->
        </div>
    </div>
</div>
<mat-card appearance="outlined">
    <div class="row">
        <div class="col-12">
            <div *ngIf="dataCollectionDefinitions.length == 0" class="none-set">No data collection definitions set</div>
            <div *ngIf="dataCollectionDefinitions.length != 0" cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
            <div class="example-box" *ngFor="let dataCollectionDefinition of dataCollectionDefinitions; let i = index" cdkDrag>
                <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                <div class="row">
                <div class="col-8">
                    <mat-form-field class="subtask_input" appearance="outline">
                        <div matPrefix class="example-handle" cdkDragHandle>
                        <mat-icon>drag_handle</mat-icon>
                        </div>
                        <!-- <mat-label>Subtask</mat-label> -->
                        <input matInput placeholder="New Data Collection Definition" type="text" [(ngModel)]="dataCollectionDefinition.name">
        
                        <button mat-icon-button matSuffix (click)="remove(i)">
                        <mat-icon>delete</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field class="subtask_input" appearance="outline">
                        <mat-label>Unit</mat-label>
                        <mat-select [(ngModel)]="dataCollectionDefinition.unit">
                            <mat-option *ngFor="let unit of unitList" [value]="unit.id">
                                {{unit.display}} <span *ngIf="unit.suffix != null">({{unit.suffix}})</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- <div class="col-1">
                    <button mat-icon-button matSuffix (click)="remove(i)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div> -->
                </div>
        
            </div>
            </div>
        </div>
    </div>

</mat-card>