import { Injectable } from '@angular/core';
import { HelperService } from 'app/services';
import { HarvestTableViewModel } from 'app/viewmodels';
import { Timestamp } from 'firebase/firestore';

@Injectable()
export class TimelineHelper {

  defaultColors = [
    '#009933', //green
    '#0066ff', //lite-blue
    '#8114ee', //lite-purple
    '#168D8D', //turqouis
    '#0000cc', //blue
    '#C62424', //red
    '#51168D', //purple
    '#C77A2D', //orange
    '#009933', //green
    '#0066ff', //lite-blue
    '#8114ee', //lite-purple
    '#168D8D', //turqouis
    '#0000cc', //blue
    '#C62424', //red
    '#51168D', //purple
    '#C77A2D', //orange
  ];

  constructor ( 
    private helperService: HelperService
  ) { }

  createTimelineDataFromEventList(eventList: Array<any>) {
    const phases = [...new Set(eventList.map(event => event.phase))]

    let dataArray = []

    for (let i = 0; i < phases.length; i++) {
      const phase = phases[i];
      let remainder =  i % this.defaultColors.length
      const events = eventList.filter(event => event.phase == phase)
      let startAndEndDate = this.getStartAndEndDateForPhase(events)
      dataArray.push(['', phase, null, this.defaultColors[remainder] || '#0066ff', ...startAndEndDate])
    }

    return dataArray;
  }

  createTimelineDataFromHarvestViewModelList(harvestViewModels: Array<HarvestTableViewModel>, onlyActive: boolean = true) {
    let dataArray = [];
    let colorsArray: Array<string> = [];

    let sorted = this.helperService.sortByDateField('startDate', harvestViewModels)
    sorted.forEach(harvest => {
      let start: Date = new Date(this.helperService.timestampToDate(harvest.startDate).setHours(0, 0, 0))
      let end: Date = new Date(this.helperService.timestampToDate(harvest.endDate).setHours(0, 0, 0))
      
      let tooltip: string = this.createTooltipHtml(harvest.name, start, end)
      let row = ['', harvest.name, tooltip, harvest.color || '#0066ff', start, end];

      if (!onlyActive) {
        colorsArray.push(harvest.color || '0066ff')
        return dataArray.push(row)
      }

      let today: Date = new Date()
      today = new Date(today.setHours(0,0,0))

      if (end >= today) {
        colorsArray.push(harvest.color || '0066ff');
        return dataArray.push(row)
      }
    });

    return { dataList: dataArray, colors: colorsArray }
  }

  getStartAndEndDateForPhase(events: Array<any>)  {
    let sorted = this.helperService.sortByDateField('startDateTime', events)
    const startDate: Date = new Date(this.helperService.timestampToDate(sorted[0].startDateTime).setHours(0, 0 ,0));
    const endDate: Date = new Date(this.helperService.timestampToDate(sorted[sorted.length - 1].startDateTime).setHours(0, 0 ,0));

    return [startDate, endDate]
  }

  calculateCurrentTimeMarker(chartData) {
    const startDateTimeStamp = chartData[0][4].getTime()
    const endDateSort = this.sortChartDataByEndDate(chartData)
    const endDateTimeStamp = endDateSort[0][5].getTime()
    const currentTime = new Date().getTime();

    return (currentTime - startDateTimeStamp) / (endDateTimeStamp - startDateTimeStamp)
  }

  sortChartDataByEndDate(chartData) {
    let sorted = [...chartData].sort((a, b) => {
      return b[5] - a[5];
    });

    return sorted;
  }

  createTooltipHtml(name: string, startDate: Date, endDate: Date) {
    let duration: number = this.helperService.calculateDaysBetweenDates(startDate, endDate)

    // let start: Date = new Date(this.helperService.timestampToDate(startDate).setHours(0, 0, 0))
    // let end: Date = new Date(this.helperService.timestampToDate(endDate).setHours(0, 0, 0))

    let shortStartDate: string = startDate.toLocaleDateString('en-us', { month: 'short', day: 'numeric' })
    let shortEndDate: string = endDate.toLocaleDateString('en-us', { month: 'short', day: 'numeric' })

    return `
    <style>
      .tt-card {
        width: 250px; /* Adjust width as needed */
      //   border: 1px solid #d1d1d1;
      //   border-radius: 8px;
      //   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      //   font-family: Arial, sans-serif;
      //   color: #000;
      //   background-color: #fff;
      }

      .tt-header {
        padding: 12px;
        border-bottom: 1px solid #d1d1d1;
        font-size: 16px;
        line-height: 1.5
      }

      .tt-content {
        padding: 12px;
        font-size: 14px;
        line-height: 1.5
      }

      .tt-content p {
        margin: 4px 0;
      }
    </style>
    <div class="tt-card">
      <div class="tt-header">
        <strong>${name}</strong>
      </div>
      <div class="tt-content">
        <p>${shortStartDate} - ${shortEndDate}</p>
        <p><strong>Duration:</strong> ${duration} days</p>
      </div>
    </div>
    `
  }
}