import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { SessionStorageService } from 'angular-web-storage';

//import { AngularFireDatabase, AngularFireList } from '@angular/fire/database'
import { AngularFirestore, AngularFirestoreCollection, DocumentData, Query } from '@angular/fire/compat/firestore';
import { QuerySnapshot } from 'firebase/firestore';
import { Observable } from 'rxjs';
import * as models from '../models';
import * as viewModels from '../viewmodels';
import { HelperService } from './helper.service';
import { Functions, HttpsCallableResult, httpsCallable } from '@angular/fire/functions';

@Injectable()
export class TemplatedTasksService {

  companyRef: AngularFirestoreCollection;

  slug: string = 'templatedTasks';

  constructor(
    private firestore: AngularFirestore,
    private helperService: HelperService,
    private storage: SessionStorageService,
    private functions: Functions
  ) {
    this.companyRef = this.firestore.collection('companies');
  }

  async get(id: string): Promise<models.TemplatedTask> {
    let companyId = this.helperService.currentCompanyId;

    let response = await this.companyRef.doc(companyId).collection(this.slug).doc(id).get().toPromise()
    let task: models.TemplatedTask = response.data();
    task.id = response.id;

    return task;
  }

  async getAll(): Promise<Array<models.TemplatedTask>> {
    let returnList: Array<models.TemplatedTask> = [];

    let companyId = this.helperService.currentCompanyId;
    let response = await this.companyRef.doc(companyId).collection(this.slug).get().toPromise();

    for (let i = 0; i < response.docs.length; i++) {
        const element = response.docs[i];
        
        let task: models.TemplatedTask = element.data();
        task.id = element.id;

        returnList.push(task);
    }
    return returnList;
  }

  async save(templatedTask: models.TemplatedTask, companyId: string | null = null): Promise<string> {

    let saveable = this.helperService.deepCopy(templatedTask);
    let uid: string;

    if(saveable.id == null || saveable.id == ""){
      //template.dateAdded = firebase.firestore.Timestamp.fromMillis(Date.now());
      uid = this.firestore.createId();
    }else{
      uid = saveable.id;
    }

    delete saveable.id;

    let cId = companyId == null ? this.helperService.currentCompanyId : companyId;

    //remove empty values
    if(saveable.tags == null){
      delete saveable.tags;
    }
    
    await this.companyRef.doc(cId).collection(this.slug).doc(uid).set(saveable);
    return uid;
  }

  delete(id: string): Promise<void> {
    let companyId = this.helperService.currentCompanyId;
    
    return this.companyRef.doc(companyId).collection(this.slug).doc(id).delete();
  }
}
