import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import * as models from 'app/models';
import * as services from 'app/services';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-data-library-migrate-dialog',
  templateUrl: './data-library-migrate-dialog.component.html',
  styleUrls: ['./data-library-migrate-dialog.component.scss']
})
export class DataLibraryMigrateDialogComponent implements OnInit, OnDestroy {

  saveClicked: boolean = false;

  selectedDataCollectionDefinitions = new FormControl('');
  dataCollectionDefinitions: models.DataCollectionDefinition[] = [];

  companyIdsToExclude: string[] = [];
  otherCompanies: models.Company[] = [];
    
  public dataCtrl: FormControl = new FormControl();
  public dataFilterCtrl: FormControl = new FormControl();
  public filteredDataCollectionDefinitions: ReplaySubject<models.DataCollectionDefinition[]> = new ReplaySubject<models.DataCollectionDefinition[]>(1);
  private filteredDataCollectionDefinitionsCache: models.DataCollectionDefinition[] = [];
  protected _onDestroy = new Subject<void>();

  protected filter() {
    if (!this.dataCollectionDefinitions) {
      return;
    }
    // get the search keyword
    let search = this.dataFilterCtrl.value;
    if (!search) {
      this.filteredDataCollectionDefinitions.next(this.dataCollectionDefinitions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the dataCollectionDefinitions
    this.filteredDataCollectionDefinitionsCache = this.dataCollectionDefinitions.filter(c => {
      return (c.name != null && c.name.toLowerCase().indexOf(search) > -1)
    })
    this.filteredDataCollectionDefinitions.next(this.filteredDataCollectionDefinitionsCache);
  }

  constructor(
    private dialogRef: MatDialogRef<DataLibraryMigrateDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private dataLibraryService: services.DataLibraryService
    , private notifierService: services.NotifierService
    , private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
    this.companyIdsToExclude = [this.helperService.currentCompanyId];
    this.getDataCollectionDefinitions();

    // listen for search field value changes
    this.dataFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filter();
      });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  change(newValue: models.DataCollectionDefinition[]) {
    this.selectedDataCollectionDefinitions.setValue(newValue);
  }

  selectAll() {
    this.selectedDataCollectionDefinitions.setValue(this.filteredDataCollectionDefinitionsCache);
  }

  async getDataCollectionDefinitions() {
    let dataCollectionDefinitions = await this.dataLibraryService.getAll()
    this.dataCollectionDefinitions = this.helperService.sortArrayByStringField(dataCollectionDefinitions, 'name');
    this.filter();
  }

  moveClick() {
    if(this.selectedDataCollectionDefinitions.value.length === 0) {
      this.notifierService.error('Must select at least one Data Library item to migrate.')
      return;
    }

    if(this.otherCompanies.length === 0) {
      this.notifierService.error('No companies selected to migrate Data Library items to.')
      return;
    }

    this.notifierService.confirm(
      'Migrate Data Library Items?', 
      `Are you sure you want to migrate ${this.selectedDataCollectionDefinitions.value.length} Data Collection Items to ${this.otherCompanies.length} different companies?`,
      () => {
        this.move();
      }, () => {})
  }

  async move() {
    this.saveClicked = true;
    for (let i = 0; i < this.otherCompanies.length; i++) {
      const company = this.otherCompanies[i];
      let dataLibrary = this.selectedDataCollectionDefinitions.value;
      await this.dataLibraryService.mergeWithCurrent(dataLibrary, company.uid);
    }

    this.saveClicked = false;
    this.notifierService.success('Data Library items migrated successfully.')
    this.dialogRef.close(true);
  }

}
