import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { DataLibraryMigrateDialogComponent } from 'app/dialogs';

import * as models from 'app/models';
import * as services from 'app/services';
import * as viewmodels from 'app/viewmodels';

@Component({
  selector: 'app-data-library-setup',
  templateUrl: './data-library-setup.component.html',
  styleUrls: ['./data-library-setup.component.scss']
})
export class DataLibrarySetupComponent implements OnInit {

  dataCollectionDefinitions: Array<models.DataCollectionDefinition> = [];
  initialDataCollectionDefinitions: Array<models.DataCollectionDefinition> = [];

  unitList: viewmodels.DataCollectionDefinitionUnit[] = [];

  saving: boolean = false;

  get dirty(): boolean {
    let x = JSON.stringify(this.initialDataCollectionDefinitions);
    let y = JSON.stringify(this.dataCollectionDefinitions);
    return x !== y;
  }

  constructor(
    private dataLibraryService: services.DataLibraryService
    , private helperService: services.HelperService
    , private firestore: AngularFirestore
    , private notifierService: services.NotifierService
    , private dialog: MatDialog
  ) { 
    this.unitList = this.helperService.sortArrayByStringField(new viewmodels.DataCollectionDefinitionUnits().list, 'display');
  }

  ngOnInit(): void {
    this.getDataLibrary();
  }

  async getDataLibrary() {
    //set grid to show that it is loading
    //this.gridOptions.api.showLoadingOverlay();

    //find the company and go get the users for that company
    let sensors = await this.dataLibraryService.getAll();
    this.dataCollectionDefinitions = this.helperService.sortArrayByStringField(sensors, 'name');
    this.initialDataCollectionDefinitions = this.helperService.deepCopy(this.dataCollectionDefinitions);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.dataCollectionDefinitions, event.previousIndex, event.currentIndex);
  }

  add() {
    this.dataCollectionDefinitions.push({
      id: this.firestore.createId(),
      name: '',
      unit: ''
    });
  }

  remove(n: number){
    this.dataCollectionDefinitions.splice(n, 1);
  }

  save() {
    if(!this.verify()) return;

    this.saving = true;
    this.dataLibraryService.update(this.dataCollectionDefinitions).then(() => {
      this.saving = false;
      this.initialDataCollectionDefinitions = this.helperService.deepCopy(this.dataCollectionDefinitions);
    });
  }

  verify() {
    let emptySubtasks = this.dataCollectionDefinitions.filter(i => i.name == '' || i.name == null || i.unit == '' || i.unit == null);

    if(emptySubtasks.length > 0) {
      this.notifierService.error('Please fill out all data collection definitions');
      return false;
    }

    return true
  }
  
  migrate() {
    this.dialog.open(DataLibraryMigrateDialogComponent);
  }

}
