<ng-container *ngIf="template != null">
    <mat-card class="sticky-header" style="margin-bottom: 20px">
        <div class="row">
            <div class="col-sm-4" style="text-align: left">
                <button class="gridbtn" mat-raised-button [routerLink]="['/console', 'templates']" title="Back to List">
                    <mat-icon>arrow_back</mat-icon>
                </button>
            </div>
            <div class="col-sm-4" style="text-align: center">
                <mat-form-field class="full-width">
                    <input matInput style="text-align: center; font-size: 26px; font-weight: bold;"
                        [(ngModel)]="template.name">
                </mat-form-field>
            </div>

            <div class="col-sm-4 right">
                <ng-container>
                    <app-harvest-save-btn [disabled]="!saveBtnEnabled" (click)="saveClick()"></app-harvest-save-btn>
                    <app-harvest-undo-btn [disabled]="!saveBtnEnabled" (click)="undoClick()"></app-harvest-undo-btn>
                </ng-container>
                <button mat-raised-button class="pad-btn" [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button *ngIf="canAdd" mat-menu-item id="template_copy_btn" [disabled]="templateDirty" title="Copy Template"
                        (click)="copy()">
                        <mat-icon aria-hidden="false" aria-label="Copy Template">file_copy</mat-icon>
                        <span>Copy Template</span>
                    </button>
                    <button mat-menu-item [disabled]="templateDirty" title="Migrate Template"
                        (click)="migrate()">
                        <mat-icon aria-hidden="false" aria-label="Migrate Template">trending_flat</mat-icon>
                        <span>Migrate Template</span>
                    </button>
                    <button mat-menu-item id="template_export_btn" [disabled]="templateDirty" title="Export Template"
                        (click)="export()">
                        <mat-icon aria-hidden="false" aria-label="Export Template">file_download</mat-icon>
                        <span>Export Template</span>
                    </button>
                    <button *ngIf="canDelete" mat-menu-item id="template_delete_btn" [disabled]="templateDirty" title="Delete Template"
                        (click)="delete()">
                        <mat-icon aria-hidden="false" aria-label="Delete Template">delete</mat-icon>
                        <span>Delete</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </mat-card>

    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-4">
                <mat-form-field *ngIf="template.type !== 'secondary'">
                    <mat-label>Start day of Harvest</mat-label>
                    <mat-select [(ngModel)]="startDayOfWeek" (selectionChange)="startDayOfWeekChange($event)">
                    <mat-option>None</mat-option>
                    <mat-option [value]="day" *ngFor="let day of weekDays">{{day}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <mat-tab-group [(selectedIndex)]="selectedTab">
            <div cdkDropListGroup>
                <mat-tab *ngFor="let p of template?.phases; let i = index" [label]="p.name">
                    <ng-template mat-tab-label>
                        <div [id]="'phase'+i" class="center-label" cdkDropList [cdkDropListData]="p" cdkDropListOrientation="horizontal" (cdkDropListDropped)="dropTab($event)">
                          <div class="drag-box" style="display: inline-block" cdkDrag cdkDragRootElement=".mat-tab-label">{{p.name}}</div>
                          <button mat-icon-button class="small-icon-button" (click)="deletePhaseClick(i)" style="margin-left: 10px;">
                              <mat-icon aria-hidden="false" aria-label="Remove Phase">delete</mat-icon>
                          </button>
                          <button mat-icon-button class="md-icon-button" style="color: red;" disabled *ngIf="i == indexOfHarvestEnd">
                              <mat-icon aria-hidden="false" style="transform: rotate(180deg)">content_cut</mat-icon>
                          </button>
                        </div>
                    </ng-template>

                    <!-- <div class="container-fluid"> -->
                        <div class="row mt-3" *ngIf="template.type !== 'secondary'">
                            <div class="col-sm-3">
                                <mat-form-field class="full-width">
                                    <input id="template_phase_name" type="text" #input matInput [(ngModel)]="p.name"
                                        placeholder="Phase Name" [ngModelOptions]="{standalone: true}">
                                </mat-form-field>
                            </div>
                            <div class="col-sm-3">
                                <mat-form-field class="full-width">
                                    <input type="number" #input matInput [(ngModel)]="p.daysSinceLastPhase" (change)="daySinceChange($event)"
                                        placeholder="Days Since Last Phase Ends" [ngModelOptions]="{standalone: true}">
                                </mat-form-field>
                            </div>
                            <div class="col-sm-3">
                                <mat-checkbox [(ngModel)]="p.harvestAtEnd" (change)="endpointChange($event, p)">Harvest at end of Phase</mat-checkbox>
                            </div>
                            <div class="col-sm-3">
                                <mat-form-field id="template-phase-compliance-stage">
                                    <mat-label>Compliance Stage</mat-label>
                                    <mat-select [(ngModel)]="p.compliancePhase">
                                        <mat-option>None Selected</mat-option>
                                        <mat-option *ngFor="let compliancePhase of compliancePhases" [value]="compliancePhase">
                                            {{ compliancePhase }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div [class]="template.type !== 'secondary' ? 'row' : 'row mt-3'">
                            <div class="col-12">
                                <button mat-raised-button [matMenuTriggerFor]="addMenu" style="float: right">
                                    <mat-icon>add</mat-icon>
                                </button>
                                <mat-menu #addMenu="matMenu">
                                    <button mat-menu-item (click)="addStep(p)">
                                        <mat-icon>playlist_add</mat-icon>
                                        <span>Add Step</span>
                                    </button>
                                    <button mat-menu-item (click)="addStepFromLibrary(p)">
                                        <mat-icon>tab_inactive</mat-icon>
                                        <span>Create Step from Task Library</span>
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12">
                                <app-template-step-list 
                                    [allTags]="allTags" 
                                    [visible]="selectedTab == i" 
                                    [(steps)]="p.steps"
                                    [template]="template"
                                    [phase]="p"
                                    (replant)="replant()">
                                </app-template-step-list>
                                <!-- (recalcDayTotals)="recalcDayTotals()" -->
                            </div>
                        </div>
                        <!-- <div class="row mt-3 right">
                            <button mat-raised-button (click)="addStep()">
                                <mat-icon id="template_phase_add_step" aria-hidden="false" aria-label="Add Step">add
                                </mat-icon>
                            </button>
                        </div> -->
                    <!-- </div> -->
                </mat-tab>
            </div>

            <mat-tab disabled title="Add Phase" *ngIf="template.type !== 'secondary' || template.phases.length === 0">
                <ng-template mat-tab-label>
                    <button id="templates_phaseAdd" mat-icon-button (click)="addPhase()">
                        <mat-icon>add_circle</mat-icon>
                    </button>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>

</ng-container>