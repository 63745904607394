
<mat-form-field>
    <mat-label>Select Companies</mat-label>
    <mat-select [formControl]="selectedCompaniesFC" multiple (closed)="setSelected()">
      <mat-option>
        <ngx-mat-select-search [formControl]="companyFilterCtrl"
                                placeholderLabel="Find companies..."
                                noEntriesFoundLabel="no matching company found"></ngx-mat-select-search>
      </mat-option>
      <mat-option disabled="disabled" class="filter-option">
          <button mat-raised-button class="mat-primary fill text-sm" (click)="selectAll()">
              Select All
          </button>
          <button mat-raised-button class="mat-accent fill text-sm" style="margin-left: 10px;" (click)="companyChange([])">
              Deselect All
          </button>
      </mat-option>
      <mat-select-trigger>
          <ng-container *ngIf="selectedCompaniesFC.value?.[0] == null">
              None Selected
          </ng-container>
          <ng-container *ngIf="selectedCompaniesFC.value?.[0] != null">
              {{selectedCompaniesFC.value?.[0].name || ''}}
              <span *ngIf="(selectedCompaniesFC.value?.length || 0) > 1" class="example-additional-selection">
                  (+{{(selectedCompaniesFC.value?.length || 0) - 1}} {{selectedCompaniesFC.value?.length === 2 ? 'other' :
                  'others'}})
              </span>
          </ng-container>
      </mat-select-trigger>
      <mat-option *ngFor="let company of filteredCompanies | async" [value]="company">
        {{company.name}}
      </mat-option>
    </mat-select>
    <mat-spinner matSuffix *ngIf="loading" diameter=25 strokeWidth=4></mat-spinner>
</mat-form-field>
<!-- 
<mat-form-field>
    <mat-label>Select Companies</mat-label>
    <mat-select [formControl]="selectedCompaniesFC" multiple (closed)="setSelected()">
        <mat-option disabled="disabled" class="filter-option">
            <button mat-raised-button class="mat-primary fill text-sm" (click)="selectAll()">
                Select All
            </button>
            <button mat-raised-button class="mat-accent fill text-sm" style="margin-left: 10px;" (click)="companyChange([])">
                Deselect All
            </button>
        </mat-option>
        <mat-select-trigger>
            <ng-container *ngIf="selectedCompaniesFC.value?.[0] == null">
                None Selected
            </ng-container>
            <ng-container *ngIf="selectedCompaniesFC.value?.[0] != null">
                {{selectedCompaniesFC.value?.[0].name || ''}}
                <span *ngIf="(selectedCompaniesFC.value?.length || 0) > 1" class="example-additional-selection">
                    (+{{(selectedCompaniesFC.value?.length || 0) - 1}} {{selectedCompaniesFC.value?.length === 2 ? 'other' :
                    'others'}})
                </span>
            </ng-container>
        </mat-select-trigger>
        <mat-option *ngFor="let company of companies" [value]="company">{{company.name}}</mat-option>
    </mat-select>
</mat-form-field> -->
