import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

import * as models from '../models';
import * as viewModels from 'app/viewmodels';
import { HelperService } from './helper.service';

@Injectable()
export class DataLibraryService {

    companyRef: AngularFirestoreCollection;
    collectionId: string = 'grow-settings';
    itemId: string = 'data-library';

    constructor(
        private firestore: AngularFirestore,
        private helperService: HelperService,
    ) {
        this.companyRef = this.firestore.collection('companies');
    }

    async getAll(): Promise<Array<models.DataCollectionDefinition>> {
        let companyId = this.helperService.currentCompanyId;
        let response = this.companyRef.doc(companyId).collection(this.collectionId).doc(this.itemId);
        let dbDataLibrary: models.DbDataLibrary = await (await response.get().toPromise()).data();

        return dbDataLibrary?.list || [];
    }

    getAllSubscribe() {
        let companyId = this.helperService.currentCompanyId;
        return this.companyRef.doc(companyId).collection(this.collectionId).doc(this.itemId).valueChanges();
    }

    update(dataLibrary: Array<models.DataCollectionDefinition>) {

        dataLibrary.forEach(item => {
            if(item.id == null){
                item.id = this.firestore.createId()
            }
        });

        dataLibrary = this.helperService.removeEmptyFromList(dataLibrary);

        let dbDataLibrary: models.DbDataLibrary = {
            list: dataLibrary
        }

        let companyId = this.helperService.currentCompanyId;

        return this.firestore.collection('companies').doc(companyId)
                .collection(this.collectionId).doc(this.itemId).set(dbDataLibrary);
    }

    async mergeWithCurrent(dataLibrary: Array<models.DataCollectionDefinition>, companyId: string | null = null) {

        dataLibrary.forEach(item => {
            if(item.id == null){
                item.id = this.firestore.createId()
            }
        });

        dataLibrary = this.helperService.removeEmptyFromList(dataLibrary);

        let current = await this.getAll();

        for (let i = 0; i < current.length; i++) {
            const element = current[i];
            let found = dataLibrary.find(i => i.id == element.id);
            if(found == null){
                dataLibrary.push(element);
            }
        }

        let dbDataLibrary: models.DbDataLibrary = {
            list: dataLibrary
        }

        let cId = companyId == null ? this.helperService.currentCompanyId : companyId;

        return this.firestore.collection('companies').doc(cId)
                .collection(this.collectionId).doc(this.itemId).set(dbDataLibrary);
    }

    // async import(importRows: Array<viewModels.SensorExport>): Promise<void> {
    //     let sensors = await this.getAll();

    //     importRows.forEach(importRow => {
    //         if(sensors.find(i => i.sensorNumber == importRow.sensorNumber) == null){
    //             sensors.push(Object.assign({}, importRow));
    //         }
    //     });

    //     await this.update(sensors);
    // }
}
