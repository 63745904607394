<h2 mat-dialog-title>Migrate Template</h2>
<mat-dialog-content>

    <div class="row">
        <div class="col-sm-12">
            <app-company-select-multiple
                [companyIdsToExclude]="companyIdsToExclude"
                [(selectedCompanies)]="otherCompanies"
            ></app-company-select-multiple>
        </div>
    </div>
    
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="saveClicked">Cancel</button>
  <button mat-button (click)="moveClick()" [disabled]="saveClicked">
    <mat-spinner *ngIf="saveClicked" diameter=25 strokeWidth=4></mat-spinner>
    <span *ngIf="!saveClicked">Copy</span>
  </button>
</mat-dialog-actions>
