import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss']
})
export class TimeInputComponent implements OnInit {

  @Input() setCurrentTimeOnClick: boolean = false;

  timeValue: string;
  @Input()
  get time() {
    return this.timeValue;
  }
  @Output() timeChange = new EventEmitter();
  set time(val) {
    this.timeValue = val;
    this.timeChange.emit(this.timeValue);
  }

  internalTimeValue: string;
  get internalTime() {
    return this.internalTimeValue;
  }
  set internalTime(val) {
    this.internalTimeValue = val;

    //conver internalTime to 12 hour format
    this.time = this.convertFrom24To12Hour(this.internalTime);
  }
  
  constructor() { }

  ngOnInit(): void {
    //convert time from 12 hour to 24 hour
    if(this.time != null && this.time != '') {
      if(this.time.toLowerCase().includes('am') || this.time.toLowerCase().includes('pm')) {
        this.internalTime = this.convertFrom12HourTo24Hour(this.time);
      } else {
        let time = this.time.split(':');
        let hours = parseInt(time[0]);
        if(hours < 10) {
          time[0] = `0${hours}`;
        }
        let minutes = parseInt(time[1]);
        if(minutes < 10) {
          time[1] = `0${minutes}`;
        }
        this.internalTime = time.join(':');
      }
    }
  }

  focus(){
    if (this.setCurrentTimeOnClick && (!this.internalTime || this.internalTime.trim() === '')) {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      this.internalTime = `${hours}:${minutes}`;
    }
  }

  convertFrom12HourTo24Hour(time: string): string {
    if (time && time.includes(' ')) {
      const [timePart, period] = time.split(' ');
      let [hours, minutes] = timePart.split(':').map(Number);
  
      if (period.toLowerCase() === 'pm' && hours !== 12) {
        hours += 12;
      } else if (period.toLowerCase() === 'am' && hours === 12) {
        hours = 0;
      }
  
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }
    return time;
  }

  convertFrom24To12Hour(time: string): string {
    if(time != null && time != '') {
      const timeArray = time.split(':');
      let hours = parseInt(timeArray[0]);
      const minutes = parseInt(timeArray[1]);
      const ampm = hours >= 12 ? 'pm' : 'am';

      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes.toString().padStart(2, '0');

      return `${formattedHours}:${formattedMinutes} ${ampm}`;
    }
    return time;
  }

}
