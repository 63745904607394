import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CellDoubleClickedEvent, ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { LinkCellComponent } from 'app/components/cell-components';

import * as models from 'app/models';
import * as services from 'app/services';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Timestamp } from 'firebase/firestore';
import { TaskLibraryMigrateDialogComponent } from 'app/dialogs/task-library-migrate-dialog/task-library-migrate-dialog.component';

@Component({
  selector: 'app-task-library-list',
  templateUrl: './task-library-list.component.html',
  styleUrls: ['./task-library-list.component.scss']
})
export class TaskLibraryListComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('taskLibraryList') taskLibraryList: ElementRef;
  @Input() selectionLocked: boolean = false;
  @Input() selectedId: string;
  @Output() loadedList: EventEmitter<models.TemplatedTask[]> = new EventEmitter<models.TemplatedTask[]>();
  companySub: Subscription;

  templatedTasks: models.TemplatedTask[];
  userRole: models.Role;

  private gridApi: GridApi;
  public gridOptions: GridOptions;
  private allPossibleColumns : Array<ColDef> = [
    {
      headerName: "Name",
      field: "name",
    },
  ];

  get canAdd() : boolean {
    return this.userRole?.permissions?.includes(models.Permissions.addTemplate);
  }

  get canDelete(): boolean {
    return this.userRole?.permissions?.includes(models.Permissions.deleteTemplate);
  }

  constructor(
    private helperService: services.HelperService
    , private deviceService: DeviceDetectorService
    , private router: Router
    , private templatedTaskService: services.TemplatedTasksService
    , private claimsService: services.ClaimsService
    , private dialog: MatDialog
  ) { 
    this.gridOptions = <GridOptions>{
      context: {
        componentParent: this
      },
      //rowData: this.templates,
      //columnDefs: this.createColumnDefs(),
      rowHeight: 35,
      onGridReady: (params: any) => {
        this.gridApi = params.api;
        if(!this.deviceService.isMobile()){
          this.gridOptions.api.sizeColumnsToFit();
        }
        this.load();
      },
      // frameworkComponents: {
      //     timestampRenderer: TimeStampComponent,
      // },
      rowSelection: 'single',
      defaultColDef: {
        sortable: true, resizable: true,
      },
      onSelectionChanged: this.onSelectionChanged.bind(this),
    };
  }

  async ngOnInit(): Promise<void> {
    this.userRole = await this.claimsService.getRoleAwait();
  }

  ngAfterViewInit(): void {
    this.setUpColumns();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.selectedId != null){
      this.setSelectedNodes();
    }
  }

  ngOnDestroy() {
    if(this.companySub != null){
      this.companySub.unsubscribe();
    }
  }

  load() {
    //get companyId
    let companyId = this.helperService.currentCompanyId;
    //if we have it then load
    if(companyId != null){
      //this.getStrains();
      this.getTaskLibrary();
    }

    //set up subscription to listen to company id changes
    this.companySub = this.helperService.getCurrentCompanyId().subscribe(data => {
      this.getTaskLibrary();
    })
  }

  async setSelectedNodes() {
    await this.helperService.delay(200);

    if(this.gridApi != null){
      this.gridApi.forEachNode((node, index) => {
        if(node.data.id == this.selectedId){
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      })
    }
  }

  setUpColumns() {
    let currentCols = this.allPossibleColumns;

    if(this.gridOptions != null && this.gridOptions.api != null){
      this.gridOptions.api.setColumnDefs(currentCols);
      this.gridOptions.columnApi.autoSizeAllColumns();
    }

    this.resizeCols();
  }

  async resizeCols() {
    if(!this.deviceService.isMobile() && this.gridOptions != null){
      await this.helperService.wait(10);
      const colIds = this.gridOptions.columnApi.getAllColumns().map(c => c.getColId())
      this.gridOptions.columnApi.autoSizeColumns(colIds);
      await this.helperService.wait(10);

      let columns = this.gridOptions.columnApi.getAllDisplayedColumns()
      let widthOfCols = 0;
      columns.forEach(col => {
        widthOfCols += col.getActualWidth();
      });
      if(this.taskLibraryList.nativeElement.clientWidth < widthOfCols) {
        const colIds = this.gridOptions.columnApi.getAllColumns().map(c => c.getColId())
        this.gridOptions.columnApi.autoSizeColumns(colIds)
      } else {
        this.gridOptions.api.sizeColumnsToFit();
      }
    }
  }

  onSelectionChanged() {
    if(this.selectedId == 'add'){
      return;
    }
    var selectedRows = this.gridApi.getSelectedRows();
    let selectedRow: models.TemplatedTask = selectedRows[0]; //should only be one
    if(selectedRow == null){
      this.selectedId = null;
      this.router.navigate(['/console', 'templates', 'task-library']);
      return;
    }

    this.selectedId = selectedRow.id;

    this.helperService.addQueryStringParam(models.queryStringKeys.Templatedtask, this.selectedId);
    //this.router.navigate(['/console', 'templates', 'task-library', this.selectedId]);
  }

  getTaskLibrary() {
    this.templatedTaskService.getAll().then(data => {
      this.templatedTasks = this.helperService.sortArrayByStringField(data, 'name');
      //this.gridOptions.api.setRowData(this.templatedTasks);
      this.setSelectedNodes();
      this.loadedList.emit(this.templatedTasks);
    });
  }

  refresh(templatedTask: models.TemplatedTask | null) {
    let list = this.helperService.deepCopy(this.templatedTasks);

    if(templatedTask == null){ //this means it was a delete
      list = list.filter(i => i.id != this.selectedId);
    } else {
      let index = this.templatedTasks.findIndex(i => i.id == templatedTask.id);
      if(index > -1){
        list[index] = templatedTask;
      } else {
        list.push(templatedTask);
      }
    }
    this.templatedTasks = this.helperService.sortArrayByStringField(list, 'name');
    this.loadedList.emit(this.templatedTasks);
    this.setSelectedNodes();
  }

  add() {
    //this.router.navigate(['/console', 'templates', 'task-library', 'add']);
    this.helperService.addQueryStringParam(models.queryStringKeys.Templatedtask, 'add');
  }

  migrate() {
    this.dialog.open(TaskLibraryMigrateDialogComponent, {
      data: this.templatedTasks
    });
  }

  exportAsCSV() {
    let params: any = {};
    params.processCellCallback = (params) => {
      if (params.value instanceof Timestamp) {
        return this.helperService.timestampToDateString(params.value);
      } else {
        return params.value;
      }
    };
    this.gridOptions.api.exportDataAsCsv(params);
  }

}
