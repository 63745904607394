import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import * as models from 'app/models';
import * as services from 'app/services';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-task-library-migrate-dialog',
  templateUrl: './task-library-migrate-dialog.component.html',
  styleUrls: ['./task-library-migrate-dialog.component.scss']
})
export class TaskLibraryMigrateDialogComponent implements OnInit, OnDestroy {

  saveClicked: boolean = false;

  selectedTasks = new FormControl('');
  templatedTasks: models.TemplatedTask[] = [];

  companyIdsToExclude: string[] = [];
  otherCompanies: models.Company[] = [];
  
  public taskCtrl: FormControl = new FormControl();
  public taskFilterCtrl: FormControl = new FormControl();
  public filteredTemplatedTasks: ReplaySubject<models.TemplatedTask[]> = new ReplaySubject<models.TemplatedTask[]>(1);
  private filteredTemplatedTasksCache: models.TemplatedTask[] = [];
  protected _onDestroy = new Subject<void>();

  protected filterTasks() {
    if (!this.templatedTasks) {
      return;
    }
    // get the search keyword
    let search = this.taskFilterCtrl.value;
    if (!search) {
      this.filteredTemplatedTasks.next(this.templatedTasks.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the templatedTasks
    this.filteredTemplatedTasksCache = this.templatedTasks.filter(c => {
      return (c.name != null && c.name.toLowerCase().indexOf(search) > -1)
    })
    this.filteredTemplatedTasks.next(this.filteredTemplatedTasksCache);
  }

  constructor(
    private dialogRef: MatDialogRef<TaskLibraryMigrateDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private notifierService: services.NotifierService
    , private helperService: services.HelperService
    , private templatedTasksService: services.TemplatedTasksService
  ) { }

  ngOnInit(): void {
    this.companyIdsToExclude = [this.helperService.currentCompanyId];
    this.getTemplatedTasks();
    
    // listen for search field value changes
    this.taskFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterTasks();
      });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  change(newValue: models.TemplatedTask[]) {
    this.selectedTasks.setValue(newValue);
  }

  selectAll() {
    this.selectedTasks.setValue(this.filteredTemplatedTasksCache);
  }

  async getTemplatedTasks() {
    let templatedTasks = await this.templatedTasksService.getAll()
    this.templatedTasks = this.helperService.sortArrayByStringField(templatedTasks, 'name');
    this.filterTasks();
  }

  moveClick() {

    if(this.selectedTasks.value.length === 0) {
      this.notifierService.error('Must select at least one Task Library item to migrate.')
      return;
    }

    if(this.otherCompanies.length === 0) {
      this.notifierService.error('No companies selected to migrate Task Library items to.')
      return;
    }

    this.notifierService.confirm(
      'Migrate Task Library Items?', 
      `Are you sure you want to migrate ${this.selectedTasks.value.length} Tasks to ${this.otherCompanies.length} different companies?`,
      () => {
        this.move();
      }, () => {})
  }

  async move() {
    this.saveClicked = true;
    for (let i = 0; i < this.otherCompanies.length; i++) {
      const company = this.otherCompanies[i];
      for (let j = 0; j < this.selectedTasks.value.length; j++) {
        const task = this.selectedTasks.value[j];
        await this.templatedTasksService.save(task, company.uid);
      }
    }

    this.saveClicked = false;
    this.notifierService.success('Task Library items migrated successfully.')
    this.dialogRef.close(true);
  }

}
