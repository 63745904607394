<h2 mat-dialog-title>Copy Task Library</h2>
<mat-dialog-content>
    <div class="row">
        <div class="col-sm-12">
            <mat-form-field>
                <mat-label>Select Task Library Items</mat-label>
                <mat-select [formControl]="selectedTasks" multiple>
                    <mat-option>
                      <ngx-mat-select-search [formControl]="taskFilterCtrl"
                                              placeholderLabel="Find tasks..."
                                              noEntriesFoundLabel="no matching task found"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option disabled="disabled" class="filter-option">
                        <button mat-raised-button class="mat-primary fill text-sm" (click)="selectAll()">
                            Select All
                        </button>
                        <button mat-raised-button class="mat-accent fill text-sm" style="margin-left: 10px;" (click)="change([])">
                            Deselect All
                        </button>
                    </mat-option>
                    <mat-select-trigger>
                        <ng-container *ngIf="selectedTasks.value?.[0] == null">
                            None Selected
                        </ng-container>
                        <ng-container *ngIf="selectedTasks.value?.[0] != null">
                            {{selectedTasks.value?.[0].name || ''}}
                            <span *ngIf="(selectedTasks.value?.length || 0) > 1" class="example-additional-selection">
                                (+{{(selectedTasks.value?.length || 0) - 1}} {{selectedTasks.value?.length === 2 ? 'other' :
                                'others'}})
                            </span>
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option *ngFor="let templatedTask of filteredTemplatedTasks | async" [value]="templatedTask">
                      {{templatedTask.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-12">
            <app-company-select-multiple
                [companyIdsToExclude]="companyIdsToExclude"
                [(selectedCompanies)]="otherCompanies"
            ></app-company-select-multiple>
        </div>
    </div>
    
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="saveClicked">Cancel</button>
  <button id="template_add_save" mat-button (click)="moveClick()" form="addTemplate" [disabled]="saveClicked">
    <mat-spinner *ngIf="saveClicked" diameter=25 strokeWidth=4></mat-spinner>
    <span *ngIf="!saveClicked">Copy</span>
  </button>
</mat-dialog-actions>
